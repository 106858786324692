import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import * as Typo from '../../components/Typography/typographyStyle';
import { ColoredScoreBar } from '../../components/infos/progressBar';
import Button from '@mui/material/Button';
import { GreyButton } from '../../components/buttons/buttons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import CircularProgressWithLabel from '../../components/infos/circularBar';


interface ItemCardProps {
  title: string;
  photo: string;
  id: string;
  price: number;
  desc: string;
  score: number;
  type: string;
  ethicalscore: number;
  ecologicalscore: number;
  redirectnumber: number;
  lastshown: Date;
  lastclick: Date;
}

const AlternativeCard: React.FC<ItemCardProps> = (props) => {

  const [cookies] = useCookies(['token']);
  const [openDialog, setOpenDialog] = useState(false);

  const containerStyle = {
    width: 1400,
    height: 250,
    borderRadius: 20,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'start',
  };

  const imageContainerStyle = {
    width: 190,
    height: 225,
    borderRadius: 10,
    marginLeft: 15,
    marginTop: 12,
  };

  const dividerStyle = {
    padding: '20px',
    borderRight: '1px solid',
    color: '#5E5E5E',
    margin: '20px',
  };

  const progressContainerStyle = {
    width: '120px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center'
  };

  const addArticleRedirect = () => {
    window.location.replace('/addArticle');
  };

  return (
    <Paper style={containerStyle} elevation={3}>

      <Box style={imageContainerStyle}>
        <img
          src={props.photo}
          alt="Your Image"
          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '12px', width: '300px', }}>
        <Typography style={Typo.poppinsLightWhiteStyle}>
          {props.type}
        </Typography>
        <Typography
          style={{
            ...Typo.poppinsTitleSB25,
            marginTop: '-5px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '300px',
          }}
        >
          {props.title}
        </Typography>
        <Box sx={{ alignSelf: 'center', paddingTop: '3vh' }}>
          <Box style={progressContainerStyle}>
            <CircularProgressWithLabel
              value={props.score}
              size={120}
              textFontSize={24}
              thickness={4} 
            />
          </Box>
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem sx={dividerStyle} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '30px', paddingTop: '30px' }}>
        <Typography style={{ ...Typo.poppinsUnderTitleSB15, marginTop: '30px' }}>
          Environnement:
        </Typography>
        <Box style={progressContainerStyle}>
          <Typography style={{ ...Typo.poppinsRegular15 }}>{props.ecologicalscore}/100</Typography >
          <ColoredScoreBar sx={{ marginLeft: '5px', width: '800px' }} variant="determinate" value={props.ecologicalscore} />
        </Box>

        <Typography style={{ ...Typo.poppinsUnderTitleSB15, marginTop: '30px' }}>
          Ethique:
        </Typography>
        <Box style={progressContainerStyle}>
          <Typography style={{ ...Typo.poppinsRegular15 }}>{props.ethicalscore}/100</Typography >
          <ColoredScoreBar sx={{ marginLeft: '5px', width: '800px' }} variant="determinate" value={props.ethicalscore} />
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem sx={dividerStyle} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '30px', paddingTop: '40px', width: '300px' }}>
        <Typography style={{ ...Typo.poppinsUnderTitleSB15, marginTop: '30px' }}>
          Description:
        </Typography>
        <Typography style={{ ...Typo.poppinsRegular15, maxWidth: '270px', maxHeight: '95px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {props.desc}
        </Typography>
      </Box>
      <Box
        sx={{
          alignSelf: 'flex-end',
          marginBottom: '43px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ fontSize: '35px', margin: '20px' }}>
          {props.price}€
        </Typography>
        <Button variant="contained" size="small" sx={{ ...GreyButton(), width: '150px', height: '50px' }} onClick={addArticleRedirect}>
          <Typography color={'white.light'}>Modifer l'article</Typography>

        </Button>
      </Box>
    </Paper>

  );
};

export default AlternativeCard;
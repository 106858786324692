import { Box, Button, Stack, Typography } from "@mui/material";
import { useCookies } from 'react-cookie';

import { Center } from "../../informationPage/Information";
import localShirtFullLogoGreen from "../../assets/green_logo.svg";
import whiteLogo from './../../assets/white_logo.svg';
import AccountButton from "../myAccount/accountButton";

export default function Navbar() {
    const [cookies, setCookie] = useCookies(['token', 'theme']);
    return (
        <Box position="fixed" top={0} left={0} width="100vw" zIndex={1000}>
            <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} paddingY={'1.5vh'} position={'absolute'}>
                <Button style={{ border: 'none', background: 'none', }} onClick={() => window.location.replace("/")}>
                    {window.location.pathname === "/catalog" ? (
                        <img
                            src={whiteLogo}
                            alt="White Logo"
                            style={{
                                marginLeft: '290px',
                                marginTop: "5px",
                                position: 'fixed',
                                top: '18px',
                                width: '250px',
                                height: 'auto',
                                zIndex: 1000,
                            }}
                        />
                    ) : (
                        <img
                            src={localShirtFullLogoGreen}
                            alt="Green Logo"
                            style={{ marginLeft: '2vw' }}
                        />
                    )}
                </Button>
                {!cookies.token ? (
                    <Box sx={{ boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 20%)" }} marginRight={'3vw'}>
                        <Center bgcolor={'green.main'} borderRadius={'5px'} width={'100%'} height={'100%'} paddingX={'1vw'}
                            onClick={() => window.location.replace('/login')} sx={{ ":hover": { cursor: "pointer" } }}>
                            <Typography variant={'button'} color={'white.light'}>Espace Partenaire</Typography>
                        </Center>
                    </Box>
                ) : (
                    <AccountButton />
                )}
            </Stack>
        </Box>
    )
}
